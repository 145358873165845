/// <reference types="@angular/localize" />

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { browserTracingIntegration, init, replayIntegration } from '@sentry/angular';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

const DSN_SENTRY = 'https://a114243ee789e1866f897c188fd43a6e@sentry.energiesdemain.org/25';

if (DSN_SENTRY.includes('https')) {
  init({
    dsn: DSN_SENTRY,
    release: '46fdc0e50dadb35bcc67180106ceb7763121f02b',
    integrations: [
      // Registers and configures the Tracing integration,
      // which automatically instruments your application to monitor its
      // performance, including custom Angular routing instrumentation
      browserTracingIntegration(),
      // Registers the Replay integration,
      // which automatically captures Session Replays
      replayIntegration(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', environment.api.baseUrl],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
